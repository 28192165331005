<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.row(v-if="click")
			.col-sm-6
				.widget
					.widget-header
						h1.title Click Info
					.widget-body
						.info-list
							.info-row
								label.info-label ID
								div.info-data {{ click.id }}
							.info-row
								label.info-label Request Type
								div.info-data {{ click.request_type === 1 ? 'Impression' : 'Click' }}
							.info-row
								label.info-label Time
								div.info-data {{ click.created_at }}
							.info-row
								label.info-label IP
								div.info-data {{ click.ip_fmt }}
							.info-row
								label.info-label Advanced Privacy
								div.info-data {{ click.advanced_privacy }}
							.info-row
								label.info-label Country
								div.info-data {{ click.country }}
							.info-row
								label.info-label City
								div.info-data {{ click.city ? click.city.name : 'N/A' }}
							.info-row
								label.info-label Region
								div.info-data {{ click.region ? click.region.name : 'N/A' }}
							.info-row
								label.info-label Carrier
								div.info-data {{ click.carrier ? click.carrier.name : 'N/A' }}
							.info-row
								label.info-label Language
								div.info-data {{ click.lang }}
							.info-row
								label.info-label Device
								div.info-data {{ click.device ? click.device.name : 'N/A' }}
							.info-row
								label.info-label OS
								div.info-data {{ click.os ? click.os.name : 'N/A' }}
							.info-row
								label.info-label Browser
								div.info-data {{ click.browser ? click.browser.name : 'N/A' }}
							.info-row
								label.info-label Referrer
								div.info-data {{ click.referrer }}
							.info-row
								label.info-label Sub1
								div.info-data
									span(style="word-break: break-all;") {{ click.sub1 }}
							.info-row
								label.info-label Sub2
								div.info-data {{ click.sub2 }}
							.info-row
								label.info-label Server
								div.info-data {{ click.server }}
				.widget
					.widget-header
						h2.title Parameters &amp; Headers
					.widget-body
						h4.widget-section-title Parameters
						pre {{ click.params }}
						h4.widget-section-title Headers
						pre {{ click.headers }}

			.col-sm-6
				.widget
					.widget-header
						h2.title Details
					.widget-body
						.info-list
							.info-row
								label.info-label Complex Offer
								div.info-data
									span(v-if="complexOffer")
										entity(type="complex_offer", :id="complexOffer.id", :name="complexOffer.name")
							.info-row
								label.info-label Offer
								div.info-data
									span(v-if="offer")
										entity(type="offer", :id="offer.id", :name="offer.name")
							.info-row
								label.info-label Advertiser
								div.info-data
									span(v-if="advertiser")
										entity(type="advertiser", :id="advertiser.id", :name="advertiser.name")
							hr
							.info-row
								label.info-label Placement
								div.info-data
									span(v-if="placement")
										entity(type="placement", :id="placement.id", :name="placement.name", :data="placement")
							.info-row
								label.info-label Publisher
								div.info-data
									span(v-if="publisher")
										entity(type="publisher", :id="publisher.id", :name="publisher.name")
							.info-row
								label.info-label Appname
								div.info-data
									span(v-if="appname")
										entity(type="appname", :id="appname.id", :name="appname.name")
							.info-row
								label.info-label Bundle
								div.info-data
									span(v-if="bundle")
										entity(type="bundle", :id="bundle.id", :name="bundle.name")
							.info-row
								label.info-label Subsource 1
								div.info-data
									span(v-if="sub1")
										entity(type="sub1", :id="sub1.id", :name="sub1.name")
							.info-row
								label.info-label Subsource 2
								div.info-data
									span(v-if="sub2")
										entity(type="sub2", :id="sub2.id", :name="sub2.name")
							.info-row
								label.info-label ADV Sub1
								div.info-data
									span(v-if="adv_sub1")
										entity(type="adv_sub1", :id="adv_sub1.id", :name="adv_sub1.name")
							//.info-row
								label.info-label Group
								div.info-data
									span(v-if="group")
										entity(type="group", :id="group.id", :name="group.name")

				.widget
					.widget-header
						h2.title Conversion
					.widget-body
						div(v-if="conversion").info-list
							.info-row
								label.info-label ID
								div.info-data {{ conversion.id }}
							.info-row
								label.info-label Time
								div.info-data {{ conversion.created_at }}
							.info-row
								label.info-label IP
								div.info-data {{ conversion.ip_fmt }}
							.info-row
								label.info-label Status
								div.info-data
									span.badge.badge-danger(v-if="conversion.rejected") Rejected
									span.badge.badge-secondary(v-else) New
									// span.badge.badge-success(v-else-if="conversion.reported") Reported
						div(v-if="!conversion") No conversion info

				.widget
					.widget-header
						h2.title Publisher Conversion
					.widget-body
						div(v-if="publisher_conversion").info-list
							.info-row
								label.info-label ID
								div.info-data {{ publisher_conversion.id }}
							.info-row
								label.info-label Time
								div.info-data {{ publisher_conversion.created_at }}
							.info-row
								label.info-label IP
								div.info-data {{ publisher_conversion.ip_fmt }}
							.info-row
								label.info-label Status
								div.info-data
									//span.badge.badge-danger(v-if="publisher_conversion.rejected") Rejected
									span.badge.badge-success(v-if="publisher_conversion.reported") Reported
									span.badge.badge-secondary(v-else) Not reported
							.info-row
								label.info-label Report Time
								div.info-data {{ publisher_conversion.report_time }}
						div(v-if="!publisher_conversion") No conversion info

		.row
			.col-sm-12
				.widget
					.widget-header
						h2.title Events
					.widget-body
						.ov-table-wrapper
							table.table.table-bordered.table-striped.table-condensed
								thead
									tr
										th
											.text ID
										th
											.text Type
										th
											.text Payout
										th
											.text Cost
										th
											.text Params
										th
											.text Time
								tbody
									tr(v-for="e in events")
										td {{ e.id }}
										td {{ e.type }}
										td {{ e.revenue | toCurrency }}
										td {{ e.cost | toCurrency }}
										td
											pre.sm(style="font-size: 9px;", v-if="e._showParams") {{ e.params }}
											a(@click="e._showParams = !e._showParams", href="javascript:void(0)") {{ e._showParams ? 'Hide' : 'Show'  }}
										td {{ e.created_at }}

								tfoot
									tr
										td(colspan="2") &nbsp;
										td {{ revenueSum | toCurrency }}
										td {{ costSum | toCurrency }}
										td(colspan="2") &nbsp;

				p.clearfix &nbsp;

				.widget(v-if="rejections.length")
					.widget-header
						h2.title Rejections
					.widget-body
						.ov-table-wrapper
							table.table.table-bordered.table-striped.table-condensed
								thead
									tr
										th
											.text ID
										th
											.text Rejection Type
										th
											.text Reject Reason
										th
											.text Reject Sub Reason
										th
											.text Reject Value
										th
											.text Time
								tbody
									tr(v-for="e in rejections")
										td {{ e.id }}
										td {{ e.is_post_attribution ? 'Post-Attribution' : 'Real Time' }}
										td {{ e.reject_reason }}
										td {{ e.reject_sub_reason }}
										td {{ e.reject_value }}
										td {{ e.created_at }}

				p.clearfix &nbsp;
				
				postbacks-table(:postbacks="publisherPostbacks", :back-link="{name:'conversions'}")
				
</template>
<style lang="scss">
.info-list {
  .info-row {
    font-size: 13px;
    display: flex;
    flex-direction: row;

    .info-label {
      width: 140px;
      padding: 5px;
      text-align: right;
      margin: 0;
      font-weight: 600;
    }

    .info-data {
      padding: 5px;
      flex: 1;
    }
  }

  hr {
    border-top: none;
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'ClickView',
  async beforeRouteEnter(to, from, next) {
    let clickId = to.params.id;
    let resp = null;
    try {
      resp = await Vue.ovReq.get('click/getExtendedInfo/' + clickId);
    } catch (e) {
      return next(false);
    }
    next(async (vm) => {
      vm.click = resp.click;
      vm.conversion = resp.conversion;
      vm.publisher_conversion = resp.publisher_conversion;
      vm.offer = resp.offer;
      vm.complexOffer = resp.complex_offer;
      vm.placement = resp.placement;
      vm.events = resp.events;
      vm.rejections = resp.rejections;
      vm.advertiser = resp.advertiser;
      vm.publisher = resp.publisher;
      vm.placement = resp.placement;
      vm.sub1 = resp.sub1;
      vm.sub2 = resp.sub2;
      vm.adv_sub1 = resp.adv_sub1;
      vm.appname = resp.appname;
      vm.bundle = resp.bundle;
      vm.group = resp.group;
      vm.publisherPostbacks = resp.publisher_postbacks;
      vm.revenueSum = 0;
      vm.costSum = 0;
      if (vm.events && vm.events.length > 0) {
        vm.events.forEach((e) => {
          Vue.set(e, '_showParams', false);
          vm.revenueSum += e.revenue;
          vm.costSum += e.cost;
        });
      }
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      click: null,
      offer: null,
      complexOffer: null,
      placement: null,
      advertiser: null,
      publisher: null,
      conversion: null,
      publisher_conversion: null,
      sub1: null,
      sub2: null,
      adv_sub1: null,
      appname: null,
      bundle: null,
      group: null,
      revenueSum: 0,
      costSum: 0,
      events: [],
      rejections: [],
      publisherPostbacks: [],
    };
  },
  methods: {},
};
</script>
